import React from 'react';
import { 
  BarChart, 
  XAxis, 
  YAxis, 
  Tooltip, 
  Bar, 
  ResponsiveContainer 
} from 'recharts';

export default function ListensChart(data) {
  return (
    <ResponsiveContainer 
      width="100%" 
      height={600}
    >
      <BarChart 
        data={data}
        width={600}
        height={600} 
        layout="vertical"
      >
        <XAxis type="number" hide />
        <YAxis 
          type="category" 
          width={250} 
          interval={0} 
          dataKey="song"
          style={{
            fontSize: '1rem',
            lineHeight: '2rem',
            fontFamily: 'Roboto, sans-serif',
            // fontFamily: "proxima_nova,Helvetica Neue,Helvetica,Arial,sans-serif"
          }}
        />
        <Bar 
          dataKey="count"
          barSize={100}
          fill="#323232"
          radius={[0, 10, 10, 0]}
        />
        {/* <Tooltip cursor={false} /> */}
      </BarChart>
    </ResponsiveContainer>
  )
}

