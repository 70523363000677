import React, { Component } from 'react';
import queryString from 'query-string';

import './App.css';
import ListensChart from './Chart';

const isProduction = require('./env');
// const port = isProduction ? 443 : 8080;
const backendUrl = isProduction ? 
  `https://api.forherplaylist.com` :
  `http://localhost:8080`;
const isPopulated = arr => !!arr && !!arr.length && arr.length > 0;

class App extends Component {
  constructor() {
    super();
    let userId = queryString.parse(window.location.search)?.user;
    if (userId) {
      localStorage.setItem('for-her-user-id', userId);
    } else {
      userId = localStorage.getItem('for-her-user-id');
    }
    
    this.state = {
      userId,
      listens: [],
    };
  }

  componentDidMount() {
    const { userId } = this.state;
    if (userId) {
      this.setState({ loading: true })
      const url = `${backendUrl}/listens?userId=${userId}`;
      fetch(url)
      .then(res => res.json())
      .then(data => {
        const { listens, username } = data;
        this.setState({ listens, username, loading: false });
      })
      .catch(e => console.error(e))
    }
  }

  render() {
    const { listens, username, loading } = this.state;
    if (loading) {
      return <></>
    }
    const authorizeURL = `${backendUrl}/authorize`;
    const connectSpotifyButton = (
      <div className="center-screen fh">
        <a className="login-button" href={authorizeURL}>
          Log in with Spotify
        </a>
      </div>
    );
    const mainView = (listens, username) => (
      <>
        <div className="row">
          <div id="greeting">Hi, {username}!</div>
        </div>
        <div className="center-screen white">
          {isPopulated(listens) ? ListensChart(listens) : "You haven't listened to any songs yet 😢"}
        </div>
      </>
    );


    return (
      <div className="App">
        {Boolean(username) ? mainView(listens, username) : connectSpotifyButton}
      </div>
    );
  }
}

export default App;
